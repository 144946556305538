export const ROUTES = {
  ROOT: '/',
  USERS: '/users',
  LEARNER: '/learner',
  GROUPS: '/groups',
  NEW_GROUP: '/new-group',
  NEW_GROUP_CONFIRMATION: '/new-group-confirmation',
  GROUP_DETAIL: '/group-detail',
  EDIT_GROUP: '/edit-group',
  GROUP_BULK_ENROLLING: '/group-bulk-enrolling',
  GROUP_INDIVIDUAL_ENROLLING: '/group-individual-enrolling',
  ADD_USERS_TO_GROUP: '/add-users-to-group',
  GROUP_ADDING_NEW_MEMBER: '/add-new-member',
  COURSES: '/courses',
  NO_PAGE_FOUND: '/*',
  DASHBOARD: '/dashboard',
  ANALYTICS: '/analytics',
  ADD_USER: '/users/add-user',
  BULK_ENROLLING: '/users/bulk-enrollment',
  ENROLL: '/enroll',
};

export const ROLES = {
  role_1: {
    id: 1,
    label: 'Admin',
    isChecked: false,
    isApplied: false,
  },
  role_3: {
    id: 3,
    label: 'Training Manager',
    isChecked: false,
    isApplied: false,
  },
  role_4: {
    id: 4,
    label: 'Learner',
    isChecked: false,
    isApplied: false,
  },
};

// eslint-disable-next-line max-len
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ONLY_ALPHABETS_REGEX = /^[A-Za-z\s]+$/;
export const USERNAME_REGEX = /^[\w.@+-]+$/;
export const ADMIN = 1;
export const TRAINING_MANAGER = 3;
export const LEARNER = 4;
export const USER_ROLES = {
  [ADMIN]: 'Admin',
  [TRAINING_MANAGER]: 'Training Manager',
  [LEARNER]: 'Learner',
};

export const CONTACT_EMAIL = 'contact@ilmx.org';

export const LANGUAGES = [
  {
    code: 'ur',
    value: 'Urdu',
    isChecked: false,
    isApplied: false,
  },
  {
    code: 'en',
    value: 'English',
    isChecked: false,
    isApplied: false,
  },
];

export const LEARNER_STATUS = {
  active: {
    code: 'active',
    value: 'Active',
    isChecked: false,
    isApplied: false,
  },
  inactive: {
    code: 'inactive',
    value: 'Inactive',
    isChecked: false,
    isApplied: false,
  },
};

export const PROGRESS_FILTERS = {
  in_progress: false,
  completed: false,
};

export const PROGRESS_FILTERS_DATA = {
  in_progress: {
    label: 'In Progress',
    courseNote: 'Courses that are currently In Progress.',
    learnerNote: 'Learners that have courses In Progress.',
  },
  completed: {
    label: 'Completed',
    courseNote: 'Courses with a 100% completion rate.',
    learnerNote: 'Learners that have completed all their enrolled courses 100%.',
  },
};

export const EMPTY_LIST_PLACEHOLDER = '--';

export const INITIAL_STATE_FETCH_LEARNERS = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  filters: {
    page: 1,
    page_size: 10,
    progress_filters: { ...PROGRESS_FILTERS },
    search: '',
  },
};

export const INITIAL_STATE_FETCH_COURSES = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  filters: {
    page: 1,
    page_size: 10,
    progress_filters: { ...PROGRESS_FILTERS },
    search: '',
  },
};

export const INITIAL_STATE_LOGGEDIN_USER = {
  name: '',
  languages: LANGUAGES,
  allLanguages: [],
  learnerStatus: {},
  learnerState: null,
  profileImage: '',
  role: null,
  roles: {},
  username: '',
  csrf_token: '',
};

export const INITIAL_STATE_FETCH_USERS = {
  count: 0,
  next: null,
  previous: null,
  totalUsersCount: 0,
  isDBUsersSelected: false,
  results: {},
  filters: {
    column: '',
    direction: 'asc',
    ordering: '',
    languages: [],
    roles: [],
    learner_status: [],
    page: 1,
    page_size: 10,
    search: '',
  },
};

export const HTTP_STATUS = {
  OK_200: 200,
  CREATED_201: 201,
  ACCEPTED_202: 202,
  FOUND_302: 302,
  BAD_REQUEST_400: 400,
  UNAUTHORIZED_401: 401,
  FORBIDDEN_403: 403,
  NOT_FOUND_404: 404,
  METHOD_NOT_ALLOWED_405: 405,
  NOT_ACCEPTABLE_406: 406,
  REQUEST_TIMEOUT_408: 408,
  CONFLICT_409: 409,
  INTERNAL_SERVER_ERROR_500: 500,
};

export const MODES = ['honor', 'verified', 'audit'];
