/* eslint-disable */

import usersConstants from 'users/constants';
import { usersService } from 'services/users';
import { messageNotification } from 'utils/functions';
import { HTTP_STATUS } from 'utils/values';
import STRINGS from 'utils/strings';

const fetchUsers = ({ params, setProgress }) => (dispatch) => {
  usersService
    .fetchUsers(params)
    .then((response) => {
      const { count, next, previous, results } = response.data;
      const totalUsersCount = response.data.total_users_count;
      Promise.resolve(
        dispatch({
          type: usersConstants.FETCH_SUCCESS,
          results: Object.fromEntries(results.map((user) => [`user${user.id}`, { ...user, isChecked: false }])),
          previous,
          count,
          next,
          filters: params,
          totalUsersCount,
        }),
      );
      setProgress(false);
    })
    .catch(() => {
      messageNotification(
        'danger',
        STRINGS.DEFAULT_AJAX_ERROR_TITLE,
        STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
      );
      setProgress(false);
    });
};

const retrieveUserDetail = ({ userId, handleUserSuccess, handleUserError }) => {
  usersService
    .fetchUser(userId)
    .then((response) => {
      handleUserSuccess(response.data);
    })
    .catch((error) => {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND_404) {
        handleUserError(error.response);
        messageNotification(
          'danger',
          STRINGS.DEFAULT_AJAX_ERROR_TITLE,
          'No User Found!',
        );
      }
    });
};

const fetchCourses = (queryParams, setIsLoading) => (dispatch) => {
  usersService
    .fetchCourses(queryParams)
    .then((response) => {
      Promise.resolve(
        dispatch({
          type: usersConstants.FETCH_COURSES_SUCCESS,
          response,
        }),
      );
      setIsLoading(false);
    })
    .catch(() => {
      messageNotification(
        'danger',
        STRINGS.DEFAULT_AJAX_ERROR_TITLE,
        STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
      );
      setIsLoading(false);
    });
};

const bulkRegistration = ({ fileData, handleSuccessBulkRegistration }) => () => {
  usersService
    .userBulkRegistration(fileData)
    .then((response) => {
      handleSuccessBulkRegistration();
      messageNotification(
        'success',
        STRINGS.DEFAULT_AJAX_SUCCESS_TITLE,
        response.data,
      );
    })
    .catch((error) => {
      let message = STRINGS.DEFAULT_AJAX_ERROR_MESSAGE;
      if (error.response && error.response.status === HTTP_STATUS.BAD_REQUEST_400) {
        message = error.response.data;
      }
      messageNotification(
        'danger',
        STRINGS.DEFAULT_AJAX_ERROR_TITLE,
        message,
      );
      handleSuccessBulkRegistration();
    });
};

const enrollUsers = (requestData, handleSuccess, handleError) => {
  usersService
    .enrollUserInCourses(requestData)
    .then((response) => {
      handleSuccess(response.data);
    })
    .catch((error) => {
      if (error.response && error.response.status === HTTP_STATUS.BAD_REQUEST_400) {
        handleError(error.response.data);
      } else if (error.response && error.response.status === HTTP_STATUS.CONFLICT_409) {
        handleError(`${error.response.data.message}\n User ID\\s are ${error.response.data.users.toString()}`);
      } else {
        handleError(STRINGS.DEFAULT_AJAX_ERROR_MESSAGE);
      }
    });
};

const userSearch = (queryParams) => (dispatch) => {
  usersService.searchUsers(queryParams).then((response) => {
    Promise.resolve(
      dispatch({
        type: usersConstants.FETCH_USER_SEARCH_OPTIONS_SUCCESS,
        response,
      }),
    );
  })
};


const createUser = ({ params, setProgress, handleAPIErrors, handleSuccess }) => () => {
  usersService
    .registerNewUser(params)
    .then((response) => {
      handleSuccess(response.data);
      setProgress(false);
    })
    .catch((error) => {
      if (error.response && error.response.status === HTTP_STATUS.BAD_REQUEST_400) {
        handleAPIErrors(error.response.data);
      } else {
        messageNotification(
          'danger',
          STRINGS.DEFAULT_AJAX_ERROR_TITLE,
          STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
        );
      }
      setProgress(false);
    });
};

const fetchLoggedInUsers = ({ allowAccess, setProgress, setAuthMessage }) => (dispatch) => {
  usersService
    .fetchLoggedInUser()
    .then((response) => {
      setProgress(false);
      allowAccess(true);
      const { data } = response;
      Promise.resolve(
        dispatch({
          type: usersConstants.FETCH_LOGGEDIN_USER,
          id: data.id,
          name: data.name,
          role: data.role,
          username: data.username,
          isSuperuser: data.is_superuser,
          languages: data.languages,
          csrfToken: data.csrf_token,
          profileImage: data.profile_image,
          allLanguages: data.all_languages,
          learnerState: data.learner_state,
        }),
      );
    })
    .catch((error) => {
      if (!error.response) {
        setAuthMessage('Service is not running.');
      }
      if (error.response && error.response.data.detail) {
        setAuthMessage(error.response.data.detail);
      }
      setProgress(false);
      allowAccess(false);
    });
};

const updateCheckedUser = ({ value, checked }) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.USER_CHECKED,
      id: value,
      status: checked,
    }),
  );
};

const addFilterRole = (value) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.ADD_FILTER_ROLE,
      value,
    }),
  );
};

const removeFilterRole = (value) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.REMOVE_FILTER_ROLE,
      value,
    }),
  );
};

const addFilterLanguage = (value) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.ADD_FILTER_LANGUAGE,
      value,
    }),
  );
};

const removeFilterLanguage = (value) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.REMOVE_FILTER_LANGUAGE,
      value,
    }),
  );
};

const resetLanguages = (languages) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.RESET_ALL_LANGUAGES,
      languages,
    }),
  );
};

const addFilterLearnerState = (value) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.ADD_FILTER_LEARNER_STATE,
      value,
    }),
  );
};

const removeFilterLearnerState = (value) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.REMOVE_FILTER_LEARNER_STATE,
      value,
    }),
  );
};

const toggleDBUsers = (value) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.TOGGLE_DB_USERS_STATUS,
      value,
    }),
  );
};

const makeFilterBackup = () => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.FILTER_BACKUP,
    }),
  );
};

const restoreFilters = () => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.RESTORE_FILTERS,
    }),
  );
};

const toggleUserStatus = ({ id, status, setProgress }) => (dispatch) => {
  if (status) {
    usersService
      .activateUser(JSON.parse(`[${id}]`))
      .then(() => {
        Promise.resolve(
          dispatch({
            type: usersConstants.TOGGLE_USER_STATUS,
            id,
            status: true,
          }),
        );
        messageNotification(
          'success',
          STRINGS.DEFAULT_AJAX_SUCCESS_TITLE,
          STRINGS.USER_ACTIVATION_SUCCESS,
        );
        setProgress(false);
      })
      .catch(() => {
        messageNotification(
          'danger',
          STRINGS.DEFAULT_AJAX_ERROR_TITLE,
          STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
        );
        setProgress(false);
      });
  } else {
    usersService
      .deactivateUser(id)
      .then(() => {
        Promise.resolve(
          dispatch({
            type: usersConstants.TOGGLE_USER_STATUS,
            id,
            status: false,
          }),
        );
        messageNotification(
          'success',
          STRINGS.DEFAULT_AJAX_SUCCESS_TITLE,
          STRINGS.USER_DEACTIVATION_SUCCESS,
        );
        setProgress(false);
      })
      .catch(() => {
        messageNotification(
          'danger',
          STRINGS.DEFAULT_AJAX_ERROR_TITLE,
          STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
        );
        setProgress(false);
      });
  }
};

const toggleUsersStatus = ({ ids, status, setProgress }) => (dispatch) => {
  if (status) {
    usersService.activateUser(ids)
      .then(() => {
        dispatch({
          type: usersConstants.TOGGLE_USERS_STATUS,
          ids,
          status: true,
        });
        messageNotification(
          'success',
          STRINGS.DEFAULT_AJAX_SUCCESS_TITLE,
          STRINGS.USER_ACTIVATION_SUCCESS,
        );
        setProgress(false);
      })
      .catch(() => {
        messageNotification(
          'danger',
          STRINGS.DEFAULT_AJAX_ERROR_TITLE,
          STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
        );
        setProgress(false);
      });
  } else {
    usersService.deactivateUsers(ids)
      .then(() => {
        dispatch({
          type: usersConstants.TOGGLE_USERS_STATUS,
          ids,
          status: false,
        });
        messageNotification(
          'success',
          STRINGS.DEFAULT_AJAX_SUCCESS_TITLE,
          STRINGS.USER_DEACTIVATION_SUCCESS,
        );
        setProgress(false);
      })
      .catch(() => {
        messageNotification(
          'danger',
          STRINGS.DEFAULT_AJAX_ERROR_TITLE,
          STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
        );
        setProgress(false);
      });
  }
};

const resetUsersPassword = ({ ids, setProgress }) => (dispatch) => {
  usersService.sendPasswordResetEmail(ids)
    .then(() => {
      messageNotification(
        'success',
        STRINGS.DEFAULT_AJAX_SUCCESS_TITLE,
        STRINGS.PASSWORD_RESET_EMAIL_SENT_SUCCESS,
      );
      setProgress(false);
    })
    .catch(() => {
      messageNotification(
        'danger',
        STRINGS.DEFAULT_AJAX_ERROR_TITLE,
        STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
      );
      setProgress(false);
    });
};

const checkedAllUser = ({ checked }) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: usersConstants.USER_ALL_CHECKED,
      status: checked,
    }),
  );
};

/* eslint-disable import/prefer-default-export */
export const usersActions = {
  createUser,
  userSearch,
  fetchUsers,
  enrollUsers,
  fetchCourses,
  checkedAllUser,
  addFilterRole,
  removeFilterRole,
  bulkRegistration,
  retrieveUserDetail,
  addFilterLanguage,
  removeFilterLanguage,
  resetLanguages,
  addFilterLearnerState,
  removeFilterLearnerState,
  restoreFilters,
  toggleDBUsers,
  makeFilterBackup,
  toggleUserStatus,
  toggleUsersStatus,
  resetUsersPassword,
  updateCheckedUser,
  fetchLoggedInUsers,
};
