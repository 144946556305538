import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import CheckBox from 'common/checkbox';
import { capitalCase } from 'utils/functions';
import { DrawerContainer, DatePickerStyle } from 'components/drawer/styled';
import PaginationLoader from 'components/loader/paginationLoader';
import { usersActions } from 'users/actions';

import 'react-datepicker/dist/react-datepicker.css';

import { ReactComponent as IconChevronDown } from 'assets/images/icon-chevron-down.svg';

import {
  HeadingHolder,
  ButtonsHolder,
} from './styled';

const Drawer = (props) => {
  const {
    roles,
    filters,
    dispatch,
    showDrawer,
    allLanguages,
    selectedRoles,
    learnerStates,
    selectedLearnerStates,
    setShowDrawer,
    selectedLanguages,
  } = props;
  const [isExpandedView, setExpandedView] = useState(false);
  const [allTeams, setAllTeams] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [inProgress, setProgress] = useState(false);

  const [teams, setTeam] = useState({
    alw: false,
    edly: false,
    philu: false,
    nurture: false,
    pakistanx: false,
    hirestream: false,
    litmustest: false,
    sentimeter: false,
    workstream: false,
    bayareachess: false,
  });

  const isAllRolesSelected = Object.keys(roles).length === selectedRoles.length;
  const isAllLanguagesSelected = allLanguages.length === selectedLanguages.length;
  const isAllLearnerStatesSelected = Object.keys(learnerStates).length === selectedLearnerStates.length;

  const teamList = () => {
    let visibleTeams = [];
    visibleTeams = isExpandedView ? Object.keys(teams) : Object.keys(teams).slice(0, 4);
    return visibleTeams;
  };

  const updateFilterRole = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      dispatch(usersActions.addFilterRole(value));
    } else {
      dispatch(usersActions.removeFilterRole(value));
    }
  };

  const updateFilterLanguage = (event, code, value) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(usersActions.addFilterLanguage({ code, value }));
    } else {
      dispatch(usersActions.removeFilterLanguage({ code, value }));
    }
  };

  const updateFilterLearnerState = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      dispatch(usersActions.addFilterLearnerState(value));
    } else {
      dispatch(usersActions.removeFilterLearnerState(value));
    }
  };

  const updateAllTeams = (status) => {
    setAllTeams(status);
    setTeam(Object.fromEntries(Object.keys(teams).map((key) => [key, status])));
  };

  const updateTeam = (event) => {
    const { checked, value } = event.target;
    setTeam({
      ...teams,
      [value]: checked,
    });
  };

  const handleApplyFilters = () => {
    setProgress(true);
    const params = {
      ...filters,
      page: 1,
    };
    dispatch(usersActions.makeFilterBackup());
    dispatch(usersActions.fetchUsers({ params, setProgress }));
    setShowDrawer(false);
  };

  const clearAllFilters = () => {
    dispatch(usersActions.removeFilterLanguage('all'));
    dispatch(usersActions.removeFilterRole());
    dispatch(usersActions.removeFilterLearnerState());
    setAllTeams(false);
    updateAllTeams(false);
    setEndDate(new Date());
    setStartDate(new Date());
  };

  const handleCancelFilters = () => {
    dispatch(usersActions.restoreFilters());
    setShowDrawer(false);
  };

  const rolesList = Object.keys(roles).map((key) => {
    const role = roles[key];
    return (
      <li key={key}>
        <CheckBox
          checkId={role.id}
          value={role.id}
          dataLabel={role.label}
          isChecked={role.isChecked}
          callback={updateFilterRole}
        />
      </li>
    );
  });

  const languageList = allLanguages.map((language) => (
    <li key={language.code}>
      <CheckBox
        checkId={language.code}
        value={language.code}
        dataLabel={language.value}
        isChecked={language.isChecked}
        callback={(e) => updateFilterLanguage(e, language.code, language.value)}
      />
    </li>
  ));

  const learnerStatesList = Object.keys(learnerStates).map((key) => {
    const learnerState = learnerStates[key];
    return (
      <li key={key}>
        <CheckBox
          checkId={learnerState.code}
          value={learnerState.code}
          dataLabel={learnerState.value}
          isChecked={learnerState.isChecked}
          callback={updateFilterLearnerState}
        />
      </li>
    );
  });

  return (
    <DrawerContainer className={`drawer-${showDrawer ? 'shown' : 'hidden'}`}>
      <div className="drawer-content">
        <div className="drawer-header">
          <HeadingHolder>
            <h3>Filters</h3>
            <button type="button" className="link" onClick={clearAllFilters}>
              Clear All
              <span className="icon-Cancel icon" />
            </button>
          </HeadingHolder>
          <ButtonsHolder>
            <button type="button" className="outline" onClick={handleCancelFilters}>
              Cancel
              <span className="icon-Cancel icon" />
            </button>
            <button type="button" onClick={() => handleApplyFilters()}>
              Apply
              <span className="icon-Check icon" />
            </button>
          </ButtonsHolder>
        </div>
        {
          inProgress && <PaginationLoader />
        }
        <div className="drawer-body">
          <div className="drawer-row">
            <div className="drawer-col">
              <header>
                <CheckBox
                  checkId="role"
                  dataLabel="Role"
                  value="all"
                  isChecked={isAllRolesSelected}
                  callback={updateFilterRole}
                />
              </header>
              <ul className="checkbox-list">
                { rolesList }
              </ul>
            </div>
            <div className="drawer-col">
              <header>
                <CheckBox
                  checkId="language"
                  dataLabel="Language"
                  value="all"
                  isChecked={isAllLanguagesSelected}
                  callback={(e) => updateFilterLanguage(e, 'all', 'all')}
                />
              </header>
              <ul className="checkbox-list">
                { languageList }
              </ul>
            </div>
          </div>
          <div className="drawer-row">
            <div className="drawer-col">
              <header>
                <CheckBox
                  checkId="learnerState"
                  dataLabel="Learner Status"
                  value="all"
                  isChecked={isAllLearnerStatesSelected}
                  callback={updateFilterLearnerState}
                />
              </header>
              <ul className="checkbox-list">
                { learnerStatesList }
              </ul>
            </div>
          </div>
          <div className="drawer-row hidden">
            <div className="drawer-col">
              <header>
                <CheckBox
                  checkId="team"
                  value="team"
                  dataLabel="Team"
                  isChecked={allTeams}
                  callback={(event) => updateAllTeams(event.target.checked)}
                />
                <button
                  type="button"
                  className={`link list-${isExpandedView ? 'shown' : 'hidden'}`}
                  onClick={() => setExpandedView(!isExpandedView)}
                >
                  {isExpandedView ? 'Collapse' : 'Expand'} Team
                  <IconChevronDown />
                </button>
              </header>
              <ul className="checkbox-list">
                {
                  teamList().map((team) => (
                    <li key={team}>
                      <CheckBox
                        checkId={team}
                        value={team}
                        dataLabel={capitalCase(team)}
                        isChecked={teams[team]}
                        callback={updateTeam}
                      />
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="drawer-col">
              <header>
                <h4>Registartion Date</h4>
              </header>
              <DatePickerStyle>
                <span className="date-label">From</span>
                <div className="date-row">
                  <DatePicker
                    selected={startDate}
                    className="form-control"
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
              </DatePickerStyle>
              <DatePickerStyle>
                <span className="date-label">To</span>
                <div className="date-row">
                  <DatePicker
                    selected={endDate}
                    className="form-control"
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </DatePickerStyle>
            </div>
          </div>
        </div>
      </div>
    </DrawerContainer>
  );
};

function mapStateToProps(state) {
  const { filters } = state.USERSDATA;
  const { roles } = state.LOGGEDINUSER;
  const selectedRoles = [];
  Object.keys(roles).forEach((role) => {
    if (roles[role].isChecked) {
      selectedRoles.push(roles[role].id);
    }
  });
  const allLanguages = state.LOGGEDINUSER.languages;
  const selectedLanguages = [];
  allLanguages.forEach((lang) => {
    if (lang.isChecked) {
      selectedLanguages.push(lang.code);
    }
  });

  const learnerStates = state.LOGGEDINUSER.learnerStatus;
  const selectedLearnerStates = [];
  Object.keys(learnerStates).forEach((learnerState) => {
    if (learnerStates[learnerState].isChecked) {
      selectedLearnerStates.push(learnerStates[learnerState].code);
    }
  });

  return {
    filters,
    allLanguages,
    selectedLanguages,
    roles,
    selectedRoles,
    learnerStates,
    selectedLearnerStates,
  };
}

export default connect(mapStateToProps)(Drawer);
