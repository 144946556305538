const usersConstants = {
  FETCH_SUCCESS: 'FETCH_USERS',
  FETCH_FAILURE: 'FETCH_USERS_FAILURE',
  FETCH_COURSES_SUCCESS: 'FETCH_COURSES_SUCCESS',
  FETCH_USER_SEARCH_OPTIONS_SUCCESS: 'FETCH_USER_SEARCH_OPTIONS_SUCCESS',
  FETCH_COURSES_FAILURE: 'FETCH_COURSES_FAILURE',
  USER_CHECKED: 'USER_CHECKED',
  FETCH_LOGGEDIN_USER: 'FETCH_LOGGEDIN_USER',
  ADD_FILTER_ROLE: 'ADD_FILTER_ROLE',
  REMOVE_FILTER_ROLE: 'REMOVE_FILTER_ROLE',
  ADD_FILTER_LANGUAGE: 'ADD_FILTER_LANGUAGE',
  REMOVE_FILTER_LANGUAGE: 'REMOVE_FILTER_LANGUAGE',
  RESTORE_FILTERS: 'RESTORE_FILTERS',
  ADD_FILTER_LEARNER_STATE: 'ADD_FILTER_LEARNER_STATE',
  REMOVE_FILTER_LEARNER_STATE: 'REMOVE_FILTER_LEARNER_STATE',
  USER_ALL_CHECKED: 'USER_ALL_CHECKED',
  FILTER_BACKUP: 'FILTER_BACKUP',
  TOGGLE_USER_STATUS: 'TOGGLE_USER_STATUS',
  TOGGLE_USERS_STATUS: 'TOGGLE_USERS_STATUS',
  TOGGLE_DB_USERS_STATUS: 'TOGGLE_DB_USERS_STATUS',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
};

export default usersConstants;
